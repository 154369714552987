import classes from './App.module.css';
import {Vertical} from "react-hook-components";

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {useEffect} from "react";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC8kjs6zYNyfHRewsnRelyOQmowfBXUIJc",
    authDomain: "londriqu-9ecb9.firebaseapp.com",
    projectId: "londriqu-9ecb9",
    storageBucket: "londriqu-9ecb9.appspot.com",
    messagingSenderId: "1066939079387",
    appId: "1:1066939079387:web:0d2624932826bbad47cafe",
    measurementId: "G-YTHPBJGTFM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

function App() {
    useEffect(() => {
        (async () => {
            // Add a new document in collection "cities"
            await setDoc(doc(db, "cities", "LA"), {
                name: "Los Angeles",
                state: "CA",
                country: "USA"
            });
        })();
    }, []);
    return <Vertical className={classes.root} vAlign={'center'} hAlign={'center'}>
        <Vertical style={{maxWidth: 600, color: '#5f1f04', padding: '3rem'}}>
            <Vertical style={{fontSize: '1.5rem', marginBottom: '0.8rem'}}>Hello,</Vertical>
            <Vertical>Thank you for visiting our website. In the meanwhile, although we're working diligently to get it
                out there, we'd appreciate your patience while we do so. Londriqu is a washing service that operates
                entirely online.</Vertical>
            <Vertical style={{fontSize: '1.1rem', marginTop: '1rem'}}>Laundry 100% online !</Vertical>
        </Vertical>

    </Vertical>;
}

export default App;
